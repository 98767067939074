import { Component, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LandingLoginComponent } from '../landing-login/landing-login.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NumbersOnlyModule } from 'src/app/core/modules/validations/numbers-only/numbers-only.module';
import { ToastrService } from 'ngx-toastr';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { ISignUpRequest, ISignUpResponse } from 'src/app/core/models/authentication/request/login.model';
import { environment } from 'src/environments/environment.development';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { GstInfo, IAddApi4BusinessDeveloperAppRequest, IAddApi4BusinessDeveloperRequest, IAddApi4BusinessDeveloperResponse, IAggregateDetails } from 'src/app/core/models/user/user.model';
import { Observable, Subject } from 'rxjs';
import { UsersService } from 'src/app/core/services/httpcalls/users.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { NoLeadingSpacesDirective } from 'src/app/directives/no-leading-spaces.directive';
import { NoLeadingSpacesModule } from 'src/app/core/modules/validations/numbers-only/no-leading-spaces.module';
import { HandshakeService } from 'src/app/core/services/httpcalls/handshake.service';
import e from 'express';
import { AlphanumericOnlyModule } from 'src/app/core/modules/validations/alphanumeric-only.module';
@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LandingLoginComponent, RouterModule, NumbersOnlyModule, HttpClientModule, MatProgressSpinnerModule, CommonModule, NoLeadingSpacesModule,AlphanumericOnlyModule],
  providers: [LoginServiceService],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent {
  signUpForm!: FormGroup;
  isDisabled = signal(true);
  isPanDisabled: boolean = true;
  constructor(private handShakeApiCall:HandshakeService, private route: ActivatedRoute,private router: Router, private toastr: ToastrService, private httpCalls: LoginServiceService, private userService: UsersService) {
    this.signUpForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required ,Validators.pattern('^[a-zA-Z0-9 ]*$') ]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]),
      'companyName': new FormControl('', [ Validators.required]),
      'email': new FormControl('', Validators.compose([Validators.required, Validators.email])),
      'password': new FormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$")]),
      'confirmPassword': new FormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$")]),
      // 'organization': new FormControl('', Validators.required),
      'panNo': new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}$')]),
      'gstIn': new FormControl('', []),
      'mobileNo': new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$'), Validators.maxLength(10)]),
      'govRegUsername': new FormControl('', [])
    })
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const varifyCode = params['handshakeVerificationCode'];
      if (varifyCode) {
        this.handShakeApiCall.acceptInvitationRequest(varifyCode).subscribe(varificationResponse => {
          this.toastr.success("Handshake done");          
        },
          (error: { error: { message: string | undefined; }; }) => {
            this.toastr.error(error.error.message);
          })
      }
    });
  }
  invalidForm: boolean = false;
  passwordNotMatched: boolean = true;
  gstinToDetailsMap !: GstInfo | null; 

  error: { showError: boolean, message: string } = {
    showError: false,
    message: ''
  }

  password: { inputType: string, showPassword: boolean } = {
    inputType: 'password',
    showPassword: false
  }

  confirmPassword: { inputType: string, showPassword: boolean } = {
    inputType: 'password',
    showPassword: false
  }

  toggleShowPassword(passwordObj: { inputType: string, showPassword: boolean }) {
    passwordObj.showPassword = !passwordObj.showPassword;
    passwordObj.inputType = passwordObj.showPassword ? 'text' : 'password';
  }

  isFormValid(): void {

    this.passwordNotMatched = true;
    if (this.signUpForm.invalid) { // Check if form validations are correct
      this.invalidForm = true;
      return;
    }
    else {
      if (this.signUpForm.value.password == this.signUpForm.value.confirmPassword) { // Check pwd and confirmPwd
        this.passwordNotMatched = false;
        this.invalidForm = false;
      }
      if (this.passwordNotMatched) {
        this.error.message = "Password does not match";
        this.error.showError = true;
        this.invalidForm = true;
      }
      else {
        this.error.message = "";
        this.error.showError = false;
      }
      this.signUpForm.value.password;
      return;
    }
    this.invalidForm = false; // If Form is valid return True
  }

  autoFillPanFromGstin() {
    if(!(this.gstinToDetailsMap && this.signUpForm.value['gstIn'] == this.gstinToDetailsMap?.gstin)) {
      this.signUpForm.patchValue({"panNo": this.signUpForm.value['gstIn']?.slice(2, 12)}); // Extract PAN from GSTIN Number
      // Call API to fetch the Name and Other details from the MDM
      this.httpCalls.getGstinDetailsFromGstin(this.signUpForm.value['gstIn'])
        .subscribe((resp) => {
          this.signUpForm.patchValue({"companyName": resp?.body?.legalBusinessName});
          this.gstinToDetailsMap = resp?.body;
          this.signUpForm.controls['companyName'].disable();
        },
          (error) => {
            this.signUpForm.patchValue({"companyName": ''});
            this.gstinToDetailsMap = null;
            this.signUpForm.controls['companyName'].enable();
          })
    }
  }

  autoFillCompanyNameFromPan() {
    if(this.signUpForm.value['panNo'] && this.signUpForm.controls["panNo"].status != "INVALID") {
      this.httpCalls.getPanDetails(this.signUpForm.value['panNo'])
        .subscribe((resp) => {
          this.signUpForm.patchValue({"companyName": resp?.body.length > 0 ? resp?.body[0]?.legalBusinessName: ""});
          if(resp?.body.length > 0)
            this.signUpForm.controls['companyName'].disable();
          else 
          this.signUpForm.controls['companyName'].enable();
        },
          (error) => {
            this.signUpForm.patchValue({"companyName": ''});
            this.signUpForm.controls['companyName'].enable();
        })
    }
    else {
      this.signUpForm.patchValue({"companyName": ""});
      this.signUpForm.controls['companyName'].enable();
    }
  }

  onSubmit() {
    this.createUserOnBoardRequest(this.signUpForm.value);
    this.isFormValid();
    if (!this.invalidForm) {
      this.isDisabled.set(this.signUpForm.valid);
      const signUpObject: ISignUpRequest = {
        panNumber: this.signUpForm?.controls['panNo']?.value,
        companyName: this.signUpForm?.controls['companyName']?.value,
        userName: this.signUpForm.value['firstName'] + " " + this.signUpForm.value["lastName"],
        roleId: ENV_VARIABLES.ROLE_ID_USER,
        planId: ENV_VARIABLES.PLAN_ID_DEFAULT,
        planVariantId: ENV_VARIABLES.PLAN_VARIANT_ID,
        emailId: this.signUpForm.value['email'],
        mobileNumber: this.signUpForm.value['mobileNo'],
        productId: ENV_VARIABLES.PRODUCT_ID,
        password: this.signUpForm.value['password'],
        govRegUsername: this.signUpForm.value['govRegUsername'],
        fwdLink: ENV_VARIABLES.FWD_LINK,
        loginUrl: environment.UI_BASE_URL
      }
      //check if user already exists or not
      this.userService.checkUserExistsOrNot(this.signUpForm.value['email']).subscribe(data=>{
        if(!data?.body?.isExists){
      this.httpCalls.signUp(signUpObject)
        .subscribe((signUpResponse: ISignUpResponse) => {
      const onBoardObject = this.createUserOnBoardRequest(this.signUpForm.value);
      this.userService.onBoardUserWithCompanyMaster(onBoardObject).subscribe(resp => {  
        // create Api4Business account

        // this.createApi4BusinessAccount();

        this.toastr.success('Registration successful');
        this.router.navigateByUrl("/");
      },
      (error) => {
        this.toastr.error("Something went wrong!");
      })

        },
          (error) => {
            if (error.error.message == '' || error.error.message == undefined)
              this.toastr.error("Please try again");
            else
              this.toastr.error(error.error.message);
          })
  
        }
        else{
          this.toastr.error("Please use different emailId");
        }

        },(error)=>{
          this.toastr.error("Please try again");
        })
    }
    else {
      // if not
    }
  }

  createUserOnBoardRequest(value: any) {
    let onBoardObject: IAggregateDetails =
    {
      company_details: {
        name: value.companyName,
        legal_name: value.companyName, // Changed from companyName to legalName
        gst_in: value.gstIn, // Changed from gstIn to gst_in
        vat_number: "123456789",
        gst_in_status: "Active", // Changed from gstinStatus to gst_in_status
        gst_compliance_rating: "5", // Changed from gstComplianceRating to gst_compliance_rating
        last_feeling_month: "March", // Changed from lastFeelingMonth to last_feeling_month
        last_feeling_date: "2024-06-27T00:00:00.000Z", // Changed from lastFeelingDate to last_feeling_date
        cancellation_date: "2024-02-27T00:00:00.000Z",
        contact_person: value.firstName + " " + value.lastName, // Changed from contactPerson to contact_person
        email_id: value.email, // Changed from emailId to email_id
        phone_no: value.mobileNo, // Changed from phoneNo to phone_no
        payment_mode: 1,
        msme_status: false, // Changed from msmeStatus to msme_status
        created_at: "2024-03-04T00:00:00.000Z",
        created_by: 1, // Changed from createdBy to created_by
        updated_at: "2024-03-04T00:00:00.000Z",
        updated_by: 2, // Changed from updatedBy to updated_by
        verification_status: 3, // Changed from verificationStatus to verification_status
        cam_company_id: "",
        master_company_pan: value.panNo, // Changed from masterCompanyPan to master_company_pan
        gov_reg_username: value.govRegUsername
      },
      user_details: {
        user_name: value.email, // Changed from userName to user_name
        password: value.password,
        first_name: value.firstName, // Changed from firstName to first_name
        last_name: value.lastName, // Changed from lastName to last_name
        email_id: value.email, // Changed from emailId to email_id
        mobile_no: value.mobileNo, // Changed from mobileNo to mobile_no
        user_status: true, // Changed from userStatus to user_status
        activity_status: "Active", // Changed from activityStatus to activity_status
        designation: "Developer",
        branch_code: "", // Changed from branchCode to branch_code
        state_code: value.gstIn.substring(0, 2), // Changed from stateCode to state_code
        otp: "",
        pwd: value.password,
        change_pwd_flag: false, // Changed from changePwdFlag to change_pwd_flag
        last_password: value.password, // Changed from lastPassword to last_password
        last_password_change_time: "2024-03-04T12:00:00Z", // Changed from lastPasswordChangeTime to last_password_change_time
        activity_status_time: "2024-03-04T12:00:00Z", // Changed from activityStatusTime to activity_status_time
        is_email_verified: true, // Changed from isEmailVerified to is_email_verified
        user_photo: "", // Changed from userPhoto to user_photo
        created_at: "2024-03-04T12:00:00Z",
        updated_at: "2024-03-04T12:00:00Z",
        deleted_at: "2024-03-04T12:00:00Z", // Changed from deletedAt to deleted_at
      },
      company_master_details: {
        name: value.companyName, // companyName is used as per your original key, assuming value.companyName is correct
        legal_name: value.companyName, // Changed from legalName to legal_name
        gstin: value.gstIn,
        vat_number: "SampleVAT12345",
        gstin_status: "Active", // Changed from gstinStatus to gstin_status
        gst_compliance_rating: "5", // Changed from gstComplianceRating to gst_compliance_rating
        last_feeling_month: "March", // Changed from lastFeelingMonth to last_feeling_month
        last_feeling_date: "2024-02-27", // Changed from lastFeelingDate to last_feeling_date
        cancellation_date: "2024-02-27",
        contact_person: value.email, // Changed from contactPerson to contact_person
        email_id: value.email, // Changed from emailId to email_id
        phone_no: value.mobileNo, // Changed from phoneNo to phone_no
        payment_mode: 1,
        msme_status: true, // Changed from msmeStatus to msme_status
        state_code_id: parseInt(value.gstIn.substring(0, 2)), // Changed from stateCodeId to state_code_id, assuming conversion to number is needed
        tradename: value.companyName, // tradename is as per your original key
        pos: "01",
        address_1: "Pune", // Changed from address1 to address_1
        address_2: "Suite 100", // Changed from address2 to address_2
        location: "Sample Location",
        zipcode: "123456",
        city: "Pune",
        state: "MH",
        country: "IND",
        email_id2: value.email, // Changed from emailId2 to email_id2
        contact_no1: value.mobileNo, // Changed from contactNo1 to contact_no1
        contact_no2: "", // Changed from contactNo2 to contact_no2
        pan_number: this.signUpForm?.controls['panNo']?.value, // Changed from panNumber to pan_number
        payment_terms: "Net 30",
        bank_name: "Sample Bank", // Changed from bankName to bank_name
        bank_account_number: "123456789012", // Changed from bankAccountNumber to bank_account_number
        ifsc_code: "SBIN0000001", // Changed from ifscCode to ifsc_code
        pin: "414001",
        email_id1: value.email, // Changed from emailId1 to email_id1
        created_at: "2024-03-04T12:00:00Z",
        updated_at: "2024-03-04T12:00:00Z",
        created_by: 1, // Changed from createdBy to created_by
        updated_by: 2, // Changed from updatedBy to updated_by
        deleted_at: "" // Changed from deletedAt to deleted_at
      }
    }

    return onBoardObject;
  }
}
