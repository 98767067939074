import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LandingLoginComponent } from '../landing-login/landing-login.component';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BreadcrumbComponent } from 'src/app/components/breadcrumb/breadcrumb.component';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { HttpClientModule } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { IVerificatiionCodeResponse } from 'src/app/core/models/authentication/request/login.model';
import { UsersService } from 'src/app/core/services/httpcalls/users.service';
import { IUserRequest } from 'src/app/core/models/user/user.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-set-password',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LandingLoginComponent, RouterLink, BreadcrumbComponent, HttpClientModule, CommonModule],
  providers: [LoginServiceService, UsersService],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss'
})
export class SetPasswordComponent implements OnInit {
  invalidForm: boolean = true;
  passwordNotMatched: boolean = true;
  isChecked: boolean = true;
  invitedEmail: string = "";
  arrayOfHeadings: Array<{ title: string, link: string, isActive: boolean }> = [{ title: "test", link: "/signUp", isActive: false }, { title: "test2", link: "/signUp", isActive: true }];

  toastr: ToastrService = inject(ToastrService);
  loginHttpService: LoginServiceService = inject(LoginServiceService);
  userService: UsersService = inject(UsersService);

  error: { showError: boolean, message: string } = {
    showError: false,
    message: ''
  }

  setPasswordForm = new FormGroup({
    email: new FormControl({ value: '', disabled: true }, Validators.email),
    password: new FormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$")]),
    confirmPassword: new FormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$")])
  });

  password: { inputType: string, showPassword: boolean } = {
    inputType: 'password',
    showPassword: false
  }

  confirmPassword: { inputType: string, showPassword: boolean } = {
    inputType: 'password',
    showPassword: false
  }

  verificationCode: string = '';
  isUserInvited: boolean = false;
  allowSetPwd: boolean = false;

  // Dependency Injection
  route: ActivatedRoute = inject(ActivatedRoute);
  router: Router = inject(Router);


  // ngOnInit
  ngOnInit() {
    this.route.queryParams.subscribe((paramArray) => {
      this.verificationCode = paramArray['verificationCode'];
    });
    this.loginHttpService.userVerificationForResetPwd(this.verificationCode)
      .subscribe({
        next: (data: IVerificatiionCodeResponse) => {
          this.isUserInvited = data.acceptInviteFlag;
          this.allowSetPwd = data.allowSetPwdFlag;
          this.invitedEmail = data.userName;
          this.setPasswordForm.controls.email.setValue(data.userName); // set this username in the email id field

          if (this.isUserInvited) {
            this.isChecked = false;
            if ((this.isUserInvited && !this.allowSetPwd)) {
              this.userService.acceptInvite(this.verificationCode).subscribe(data => {
                this.toastr.success("success");
                this.toastr.success("please login using correct credentials");
                this.router.navigate(['/']);
              });
            }

          }

          if (this.isUserInvited) {

          }
          if (this.allowSetPwd && !this.isUserInvited) {
            // Handling only request's which have allowPwdSet = true and invitation = false

          }

        },
        error: (error) => {
          this.invalidForm = true;
          // this.toastr.error(error?.error?.message);
          this.toastr.error('The invitation link has expired. If you have already accepted the invitation,please login using correct credentials or reach out to your administrator for assistance.', '', {
            enableHtml: true  // This allows HTML content within the toast message
          });
          this.router.navigate(['/']);
          
        }
      });
  }

  toggleShowPassword(passwordObj: { inputType: string, showPassword: boolean }) {
    passwordObj.showPassword = !passwordObj.showPassword;
    passwordObj.inputType = passwordObj.showPassword ? 'text' : 'password';
  }

  
  setPasword() {
    if ((!this.isUserInvited && this.allowSetPwd) || (this.allowSetPwd && this.isUserInvited)) {
      if (this.setPasswordForm.controls.email.value && this.setPasswordForm.value.password && this.setPasswordForm.value.confirmPassword) {
        this.loginHttpService.setPassword({
          userName: this.setPasswordForm.controls.email.value,
          password: this.setPasswordForm.value.password,
          confirmPassword: this.setPasswordForm.value.confirmPassword,
          checked: this.isChecked
        }
        )
          .subscribe({
            next: (data) => {
              this.toastr.success("Password set Successfully");
              setTimeout(() => {
                this.toastr.success("please login using correct credentials");
                this.router.navigate(['/']);
              }, 1000);
            },
            error: (error) => {
              if (error.status == 400)
                this.toastr.error("Bad Request. Please try again");
              else {
                this.toastr.error("Something went wrong.");
              }
            }
          });
      }
    }
    else {
      // Handle this for invited user
    }
  }
  onSubmit() {
    if (this.isUserInvited) {
      this.userService.acceptInvite(this.verificationCode).subscribe(data => {
        this.setPasword();
      },
      (error)=>{
        this.toastr.error("please try again");
      });
    }
    else {
      this.setPasword();
    }

  }
}
