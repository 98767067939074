import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlphanumericDirective } from 'src/app/directives/allow-alphanumeric-only.directive';



@NgModule({
  declarations: [ AlphanumericDirective],
  imports: [
    CommonModule
  ],
  exports:[AlphanumericDirective]
})
export class AlphanumericOnlyModule { }
