<app-landing-login>
    <div class="login__container">
        @if(renderSignin) {
            <form class="login-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
                <span class="sub-heading">Powered by</span>
                <img src="assets/images/login/payinvoice-logo.png" alt="payinvoice-logo" class="payinvoice-image">
                <span class="welcome-heading">Welcome User</span>
                <span class="sub-heading">Enter your email and password to sign in</span>
                <div class="login__input-group">
                    <label for="username" class="login__input-label login__required-field">Email</label>
                    <input type="text" placeholder="Your Email Address" formControlName="username" appNoLeadingSpaces
                        class="login__input-field " />
                    <div class="login__password-error-message">
                        @if (this.signInForm.controls['username'].invalid && this.signInForm.controls['username'].touched &&
                        this.signInForm.controls['username'].value=="")
                        {
                        <span>This Field Is Required</span>
                        }
                        @else if(this.signInForm.controls['username'].invalid &&
                        this.signInForm.controls['username'].touched )
                        {
                        <span>Please Enter Correct Email Address</span>
                        }
                    </div>
                </div>

                <div class="login__input-group">
                    <label for="input-text-login" class="login__required-field">Password</label>
                    <div class="login__password-div">
                        <div class="login__password-eye">
                            <input [type]="password.inputType" id="input-text-login" class="login__input-field-password"
                                formControlName="password" placeholder="Password">
                            <span type="button" (click)="toggleShowPassword(password)"
                                class=".login__input-field-password-eye">
                                @if (!password.showPassword) { <img class="login__password-eye-image"
                                    src="assets/images/login/hide-password.svg" alt="eye"> }
                                @else { <img class="login__password-eye-image" src="assets/images/login/show-password.svg"
                                    alt="eye"> }
                            </span>
                        </div>
                        <div class="extEnd">
                            <!-- <span> -->
                                <a [routerLink]="['/forgot-password']" class="forgot-password">Forgot
                                    Password?</a>
                                <!-- </span> -->
                        </div>
                        <div class="login__password-error-message">
                            @if (this.signInForm.controls['password'].invalid &&
                            this.signInForm.controls['password'].touched)
                            { <span>This Field Is Required</span> }
                        </div>
                    </div>
                </div>

                <div class="sign-in">
                    <button type="submit" class="submit-button" [disabled]="!this.signInForm.valid">SIGN IN</button>
                </div>
                <div class="signup">
                    Don't Have An Account? <a routerLink="signUp" class="login__anchor-tag">Sign Up</a>
                </div>

            </form>
        }
        @else {
            <app-login-otp />
        }
    </div>

</app-landing-login>