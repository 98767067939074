import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LandingLoginComponent } from '../landing-login/landing-login.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { HttpClientModule } from '@angular/common/http';
import { ILogin, supplierPortalConstants } from '../../../../app/core/models/authentication/request/login.model'
import { CommonModule } from '@angular/common';
import { ISupplierToBuyerStatusEnableRequest } from 'src/app/core/models/handshake/supplierToBuyerHandshake';
import { MatSelectModule } from '@angular/material/select';
import { LoginOtpComponent } from '../login-otp/login-otp.component';
import { NoLeadingSpacesModule } from 'src/app/core/modules/validations/numbers-only/no-leading-spaces.module';
import { handshakeStatusData } from '../../../core/models/customer-master/customer-master.model';
import { HandshakeService } from 'src/app/core/services/httpcalls/handshake.service';
@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LandingLoginComponent, RouterModule, HttpClientModule, CommonModule, MatSelectModule, LoginOtpComponent, NoLeadingSpacesModule],
  providers: [LoginServiceService],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})
export class SignInComponent implements OnInit {
  signInForm!: FormGroup;
  varifyCode: string = "";
  renderSignin: boolean = true;
  constructor(private router: Router, private toastr: ToastrService, private httpCalls: LoginServiceService, private route: ActivatedRoute,private handshakeService:HandshakeService) {
    this.signInForm = new FormGroup({
      'username': new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      'password': new FormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$")])
    })
  }
  ngOnInit(): void {
    
    let handshakeVerificatioCode;
    this.route.fragment.subscribe((fragment: any) => {
      
      if (fragment.includes("handshakeVerificationCode")) {
        const urlString = fragment;
        const startIndex = urlString.indexOf('handshakeVerificationCode=') + 'handshakeVerificationCode='.length;
        let endIndex = urlString.indexOf('&', startIndex);
        if (endIndex === -1) {
          endIndex = urlString.length; 
        }
        const handshakeVerificationCode = urlString.substring(startIndex, endIndex);
        this.handshakeService.acceptInvitationRequest(handshakeVerificationCode).subscribe(data=>{
          this.toastr.success("Handshake request Accepted");
        },
      (error)=>{
        
        this.toastr.error(error?.error?.message);
      })
        
      }
    })

    this.route.queryParams.subscribe(params => {
      
      const varifyCode = params['verifyCode'];
      const BuyerEamilId = params['SupplierToBuyer'];
      if (varifyCode) {
        this.httpCalls.varifyMail(varifyCode).subscribe(varificationResponse => {
          this.toastr.success(varificationResponse.response);
        },
          (error) => {
            this.toastr.error(error.error.message);
          })
      }
      else if (BuyerEamilId) {
        const supplierToBuyerStatusEnableRequest: ISupplierToBuyerStatusEnableRequest = {
          buyerEmailId: BuyerEamilId,
          supplierEmailId: "pratik.patil@gmail.com",
          buyerId: params["buyerId"],
          supplierId: params["supplierId"],
        }
        this.httpCalls.enabelSupplierToBuyerConnect(supplierToBuyerStatusEnableRequest).subscribe(response => {
          this.toastr.success("Handshake done successfully");
        },
          (error) => {
            if (error?.error?.text.includes("Connection from supplier to buyer successful")) {
              this.toastr.success("Handshake done successfully");
            }
          })
      }
    });
  }
  password: { inputType: string, showPassword: boolean } = {
    inputType: 'password',
    showPassword: false
  }
  toggleShowPassword(passwordObj: { inputType: string, showPassword: boolean }) {
    passwordObj.showPassword = !passwordObj.showPassword;
    passwordObj.inputType = passwordObj.showPassword ? 'text' : 'password';
  }
  onSubmit() {
    if (!this.signInForm.invalid) {
      const loginObj: ILogin = { username: this.signInForm.controls['username'].value, password: this.signInForm.controls['password'].value, productId: "25" };
      this.httpCalls.signIn(loginObj).subscribe((data) => {
        localStorage.setItem("username", this.signInForm.controls['username'].value);
        // this.httpCalls.getCompanyForUser(this.signInForm.controls['username'].value, supplierPortalConstants.productId).subscribe((data) => {
        // })
        this.renderSignin = false;
        // this.router.navigateByUrl("/login-otp");
      },
        (error) => {
          this.toastr.error("Login failed");
        })
    }
  }

}
