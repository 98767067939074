<div class="mainDiv">
    <div class="image-section">
        <div class="left-panel-details">
            <div class="supplier-logo">
                <img src="assets/images/login/supplier-logo.svg" alt="supplier" />
                <span class="image-section-heading">Supplier Connect</span>
            </div>
            <ul class="feature-list">
                @for (title of titleArray; track title) {
                <li class="feature-list-item"><img src="assets/images/login/checkbox.svg"> <span>{{title}}</span>
                </li>
                }
            </ul>
            <div class="bottom-image">
                <img src="assets/images/login/supplier.svg" alt="supplier" />
            </div>
        </div>
    </div>
    <ng-content></ng-content>
</div>