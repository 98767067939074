import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appAlphanumeric]'
})
export class AlphanumericDirective {

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.control.control?.value;

    // Replace anything that is not alphanumeric or a space
    this.control.control?.setValue(initialValue.replace(/[^a-zA-Z0-9 ]/g, ''));

    if (initialValue !== this.control.control?.value) {
      event.stopPropagation();
    }
  }
}
