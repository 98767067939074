import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { LandingLoginComponent } from '../landing-login/landing-login.component';
import { NumbersOnlyModule } from 'src/app/core/modules/validations/numbers-only/numbers-only.module';
import { ToastrService } from 'ngx-toastr';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ILoginOTP, ILoginOtpResponse, IResendOtpRequest } from 'src/app/core/models/authentication/request/login.model';
import { environment } from 'src/environments/environment.development';
import { UsersService } from 'src/app/core/services/httpcalls/users.service';
import { IAddApi4BusinessDeveloperAppRequest, IAddApi4BusinessDeveloperRequest, IAddApi4BusinessDeveloperResponse, ICreateDeveloperAttributesRequest, ISubscribeForProductCamRequest } from 'src/app/core/models/user/user.model';
import { jwtDecode } from 'jwt-decode';
import { IJwtToken } from 'src/app/core/models/authentication/jwt/jwt.model';
import { Observable, Subscription, catchError, take, tap, timer } from 'rxjs';
import { response } from 'express';
import { CustomerService } from 'src/app/core/services/httpcalls/customer.service';
import { IIsApi4BusinessAccountCreatedStatusResponse } from 'src/app/core/models/customer-master/customer-master.model';
import { IEInvoiceApplicableResp, IEInvoiceSupplierDbResp, IUpdateInvoiceApplicableStatusRequest } from 'src/app/core/models/invoice-credit-notes/invoice.model';
import { InvoiceService } from 'src/app/core/services/httpcalls/invoice.service';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { UUIDGenerator } from 'src/app/core/utils/UUIDGenerator';
import { CommonModule, Time } from '@angular/common';

@Component({
  selector: 'app-login-otp',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LandingLoginComponent, RouterModule, NumbersOnlyModule, HttpClientModule, CommonModule],
  providers: [LoginServiceService],
  templateUrl: './login-otp.component.html',
  styleUrl: './login-otp.component.scss'
})
export class LoginOtpComponent implements OnInit, AfterViewInit {
  signInForm!: FormGroup;
  otp: string = "";
  supplierId !: number | null;
  supplierGstin !: string;
  isEInvoiceApplicable: boolean = false;
  camCompanyId!: number | null;
  uuid!: string;
  isApi4BusinessAccountCreatedStatusResponse!: IIsApi4BusinessAccountCreatedStatusResponse;
  enableResendOtpButton = signal<boolean>(true);
  resendCounter: number = ENV_VARIABLES.LOGIN_OTP_TIMER; 
  resendTick: number = 1000; // 1 second intervals
  countDownVerify!: Subscription;
  countDownResend!: Subscription;
  resendCountDownValueOnUI = signal<string>('');
  verifyCountDownValueOnUI = signal<string>('');
  enableSubmitButton = signal<boolean>(false);
  otpCheck: string = "";

  verifyCounter: number = ENV_VARIABLES.LOGIN_OTP_TIMER; 
  verifyTick: number = 1000; // 1 second intervals

  @ViewChild('input1') input1!: ElementRef;
  @ViewChild('input2') input2!: ElementRef;
  @ViewChild('input3') input3!: ElementRef;
  @ViewChild('input4') input4!: ElementRef;
  @ViewChild('input5') input5!: ElementRef;
  @ViewChild('input6') input6!: ElementRef;
  constructor(private router: Router, private toastr: ToastrService, private httpCalls: LoginServiceService, private userHttpCalls: UsersService,
    private companyService: CustomerService,
    private invoiceService: InvoiceService) {
    this.signInForm = new FormGroup({
    })
  }
  ngOnInit(): void {
    this.startVerifyOtpCountdown();
  }

  startResendOtpCountdown(): void {
    this.enableResendOtpButton.set(false);
    const startTime = new Date().getTime();
    const endTime = startTime + ENV_VARIABLES.LOGIN_OTP_TIMER * 1000;
    this.resendCounter = ENV_VARIABLES.LOGIN_OTP_TIMER;
    this.resendTick = 1000;

    if (this.countDownResend) {
      this.countDownResend.unsubscribe();
    }

    this.countDownResend = timer(0, this.resendTick)
      .pipe(take(this.resendCounter))
      .subscribe(() => {
        const currentTime = new Date().getTime();
        const remainingTime = Math.max(0, Math.floor((endTime - currentTime) / 1000));
        this.resendCounter = remainingTime;
        const minutes: number = Math.floor(this.resendCounter / 60);
        const seconds: number = this.resendCounter - minutes * 60;
        this.resendCountDownValueOnUI.set(`${this.pad(minutes)}:${this.pad(seconds)}`);
        
        if (this.resendCounter === 0 && this.countDownResend) {
          this.enableResendOtpButton.set(true);
          this.countDownResend.unsubscribe();
        }
      });
  }


  startVerifyOtpCountdown(): void {
    this.enableSubmitButton.set(false);
    const startTime = new Date().getTime();
    const endTime = startTime + ENV_VARIABLES.LOGIN_OTP_VERIFY_TIME * 1000;
    this.verifyCounter = ENV_VARIABLES.LOGIN_OTP_VERIFY_TIME;
    this.verifyCountDownValueOnUI.set('');
    this.verifyTick = 1000;
    
    if (this.countDownVerify) {
      this.countDownVerify.unsubscribe();
    }
    
    this.countDownVerify = timer(0, this.verifyTick)
      .pipe(take(this.verifyCounter))
      .subscribe(() => {
        const currentTime = new Date().getTime();
        const remainingTime = Math.max(0, Math.floor((endTime - currentTime) / 1000));
        this.verifyCounter = remainingTime;
        const minutes: number = Math.floor(this.verifyCounter / 60);
        const seconds: number = this.verifyCounter - minutes * 60;
        this.verifyCountDownValueOnUI.set(`${this.pad(minutes)}:${this.pad(seconds)}`);
        
        if (this.verifyCounter === 0 && this.countDownVerify) {
          this.enableResendOtpButton.set(true);
          this.countDownVerify.unsubscribe();
        }
      });
  }

  disableContinueButton(): boolean {
    this.otp = [
      this.input1.nativeElement.value,
      this.input2.nativeElement.value,
      this.input3.nativeElement.value,
      this.input4.nativeElement.value,
      this.input5.nativeElement.value,
      this.input6.nativeElement.value
    ].join('');
    if(this.otp.length == 6) {
      return false;
    }
    return true;
  }

  private pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  ngAfterViewInit(): void {
    this.input1.nativeElement.focus();
  }

  onInput(event: Event, nextInput?: HTMLInputElement, previousInput?: HTMLInputElement): void {
    const input = event.target as HTMLInputElement;
    const inputEvent = event as InputEvent;  // Type assertion here
    const value = input.value;

    if (inputEvent.inputType === 'deleteContentBackward' && previousInput && value.length === 0) {
      previousInput.focus();
    } else if (value.length === 1 && nextInput) {
      nextInput.focus();
    }
    this.otpCheck = [
      this.input1.nativeElement.value,
      this.input2.nativeElement.value,
      this.input3.nativeElement.value,
      this.input4.nativeElement.value,
      this.input5.nativeElement.value,
      this.input6.nativeElement.value
    ].join('');
  }

  onKeyDown(event: KeyboardEvent, currentInput: HTMLInputElement, prevInput: HTMLInputElement | undefined): void {
    if (event.key === "Backspace" && prevInput && !currentInput.value) {
      prevInput.focus();
    }
  }

  containsOnlyNumbers(str: string) {
    return /^\d+$/.test(str);
  }

  onPaste(event: ClipboardEvent, input: HTMLInputElement[]) {
    const pasteData = event?.clipboardData?.getData('text');
    if (!pasteData) return;
    if (pasteData && this.containsOnlyNumbers(pasteData)) {
      this.otp = pasteData;
      const digits = pasteData.split('').slice(0, pasteData.length);
      digits.forEach((digit) => {
      })

      digits.forEach((digit, index) => {
        if (input[index]) {
          input[index].value = digit;
          if (input[index + 1]) {
            input[index + 1].focus();
          }
        }
      });
    }
  }
  onSubmit() {
    this.otp = [
      this.input1.nativeElement.value,
      this.input2.nativeElement.value,
      this.input3.nativeElement.value,
      this.input4.nativeElement.value,
      this.input5.nativeElement.value,
      this.input6.nativeElement.value
    ].join('');
    const loginOtpObject: ILoginOTP = { username: localStorage.getItem("username") || "", productId: "25", jwt: localStorage.getItem("jwtToken") || "", otp: this.otp };
    this.httpCalls.loginOtpCheck(loginOtpObject).subscribe((data) => {
      const loginOtpResponse: ILoginOtpResponse = data;
      localStorage.setItem("jwtToken", loginOtpResponse.jwt);
      const decodedToken = jwtDecode<IJwtToken>(loginOtpResponse.jwt);
      
      const username = localStorage.getItem("username") || "";
      this.uuid = UUIDGenerator.generateUUID();
      this.userHttpCalls.getCompaniesByUser(username, decodedToken?.companyId, this.uuid).subscribe(data => {
        const supplierGstIn = data.gstInList[0];
        localStorage.setItem("supplierId", Object.keys(data.companyList)[0]);
        localStorage.setItem("supplierGstIn", supplierGstIn != null ? supplierGstIn.toString() : '');
        localStorage.setItem("supplierCompanyMailId", Object.values(data.companyList)[0]);
        localStorage.setItem("pan", data.pan);
        localStorage.setItem("name", data.name);
        localStorage.setItem("companyMasterId", data.companyMasterId != null ? data.companyMasterId.toString() : '');
        this.supplierGstin = supplierGstIn.toString();
        this.supplierId = Number.parseInt(Object.keys(data.companyList)[0]);
        if (data) {
          localStorage.setItem("companyId", decodedToken?.companyId.toString());
          this.camCompanyId = Number.parseInt(decodedToken?.companyId.toString());
          localStorage.setItem("userID", decodedToken?.userId.toString());

          this.getIsApi4BusinessAccountCreatedStatus(Number.parseInt(Object.keys(data.companyList)[0]), username, !this.uuid ? UUIDGenerator.generateUUID(): this.uuid).subscribe((resp: IIsApi4BusinessAccountCreatedStatusResponse) => {
            this.isApi4BusinessAccountCreatedStatusResponse = resp;
            if (this.isApi4BusinessAccountCreatedStatusResponse.body.isApi4BusinessAccountCreated) {
              this.toastr.success('Login Success');
              this.router.navigateByUrl("/app");
              return;
            }
            const subscribeReq: ISubscribeForProductCamRequest = {
              planId: ENV_VARIABLES.API4BUSINESS_PLAN_ID,
              companyId: Number.parseInt(decodedToken?.companyId.toString()),
              productId: ENV_VARIABLES.API4BUSINESS_PRODUCT_ID,
              status: 1
            }
            this.subscribeForProductCam(subscribeReq, Number.parseInt(decodedToken?.userId.toString()), !this.uuid ? UUIDGenerator.generateUUID(): this.uuid).subscribe((resp) => {
              this.createApi4BusinessAccount();
              // this.router.navigateByUrl("/app");
            });
            // this.createApi4BusinessAccount();
            // this.router.navigateByUrl("/app");
          });
        }
        else
          this.toastr.error("Something went wrong, please try again with new OTP")
      }, (error) => {
        this.toastr.error("Something went wrong, please try again with new OTP")
      })
    },
      (error) => {
        this.toastr.error(error.error.message);
      });
  }

  resendOtp() {
    const otpRequest: IResendOtpRequest = {
      username: localStorage.getItem("username") || "",
      jwt: "",
      productId: ENV_VARIABLES.PRODUCT_ID,
      otp: ""
    }
    this.httpCalls.resendOtp(otpRequest).subscribe(otpResponse => {
      this.toastr.success('OTP sent successfully');
      this.startResendOtpCountdown();
      this.startVerifyOtpCountdown();
    },
      (error) => {
        this.toastr.error(error.error.message);
      }
    )

  }

  getIsApi4BusinessAccountCreatedStatus(suppCompId: number, username: string, uuid: string): Observable<any> {
    return this.companyService.getIsApi4BusinessAccountCreatedStatus(suppCompId, username, uuid).pipe(
        tap((resp: IIsApi4BusinessAccountCreatedStatusResponse) => {
          return resp;
        }),
        catchError((error) => {
          this.toastr.error("Error - " + error);
          throw error; // Re-throwing the error for the caller to handle
        })
    );
  }

  subscribeForProductCam(subscribeReq: ISubscribeForProductCamRequest, userId: number, uuid: string): Observable<any> {
    
    return this.httpCalls.subscribeForProductCam(subscribeReq, userId, uuid).pipe(
        tap((resp) => {
          // this.toastr.info("Success for API : Subscribe CAM");
          return resp;
        }),
        catchError((error) => {
          if(error?.error?.message?.contains("Already")) {
            return error;
          }
          this.toastr.error(error?.error?.message);
          throw error; // Re-throwing the error for the caller to handle
        })
    );
  }

  createApi4BusinessAccount() {
    const isAccountCreated = false;

    const developerRequest: IAddApi4BusinessDeveloperRequest = {
      email: this.isApi4BusinessAccountCreatedStatusResponse?.body?.email,
      firstName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.firstName,
      lastName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.lastName,
      userName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.email,
      status: 'active'
    }
    this.httpCalls.createDeveloper(developerRequest)
      .subscribe((resp: IAddApi4BusinessDeveloperResponse) => {
        if (resp && resp.responseCode && resp.responseCode == 200) {
          // this.toastr.success("Developer created in Api4Business");

          const developerAppRequest: IAddApi4BusinessDeveloperAppRequest = {
            userName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.email,
            status: 'approved',
            keyExpiresIn: 7.884e+9,
            apiProducts: environment.API4BUSINESS_PRODUCTS
          }

          // Create Developer App
          this.httpCalls.createDeveloperApp(developerAppRequest)
            .subscribe((resp: IAddApi4BusinessDeveloperResponse) => {
              if (resp && resp.responseCode && resp.responseCode == 200) {
                // this.toastr.success("Developer App created in Api4Business");
              }

              // let developerAppAttributesRequest: {};
              const gstin = this.supplierGstin;
              const developerAppAttributesRequest = { [gstin]: { govUsername: this.isApi4BusinessAccountCreatedStatusResponse?.body?.govUsername } };
              const developerAttributeRequest: ICreateDeveloperAttributesRequest = {
                gstAttribute: developerAppAttributesRequest
              }
              // Create custom attributes API

              this.httpCalls.createDeveloperAppAttributes(developerAttributeRequest)
                .subscribe((resp) => {
                  const panNumber = localStorage.getItem("pan");
                  this.companyService.updateApi4BusinessAccountCreationStatus(panNumber, this.camCompanyId)
                    .subscribe((resp) => {
                      this.toastr.success("Account creation successful");

                      this.checkEInvoiceStatus();
                      localStorage.setItem("isFirstTimeLogin", "true");
                      this.router.navigateByUrl("/app");
                    },
                      (error) => {
                        this.toastr.error("Error occured while changing status of account creation");
                      })
                },
                  (error) => {
                    this.toastr.error("Error while creating developer App Attributes");
                  })
            },
              (error) => {
                this.toastr.error("Error occured while creating Developer App");
              });
        }
      },
        (error) => {
          this.toastr.error("Error while creating Developer in Api4Business");
        });
  }

  checkEInvoiceStatus() {
    if (this.supplierGstin) {
      this.invoiceService.getAndUpdateEInvoiceStatusFromGov(this.supplierGstin, this.supplierId?.toString(), this.invoiceService.getAndUpdateEInvoiceStatusFromGov, "Cannot get E-Invoice status").subscribe(() => {

      })
    }
  }

  async callFirstApi() {
    try {
      const developerRequest: IAddApi4BusinessDeveloperRequest = {
        email: this.isApi4BusinessAccountCreatedStatusResponse?.body?.email,
        firstName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.firstName,
        lastName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.lastName,
        userName: this.isApi4BusinessAccountCreatedStatusResponse?.body?.email,
        status: 'active'
      }
      const resp: IAddApi4BusinessDeveloperResponse | undefined = await this.httpCalls.createDeveloper(developerRequest).toPromise();
      if (resp && resp.responseCode && resp.responseCode == 200) {

      }
    } catch (error) {
      this.toastr.error("Error while creating Developer in Api4Business");
    }
  }

}
