
    <form class="login-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
        <div class="main-div">
            <div class="otp-image">
                <img src="assets/images/login/otp-icon.svg">
            </div>
            <h2 class="login-heading">OTP Verification</h2>
            <span class="sent-otp-headline">We have sent you <span class="otp-color">One Time Password</span> to your email</span>
            <span class="sent-otp-headline enter-otp-text">Please Enter OTP</span>
            <h3>{{verifyCountDownValueOnUI()}}</h3>
            @if(this.verifyCounter <= 0 && this.countDownVerify) {
                <span class="otp-expired-span">OTP has Expired. Please resend the OTP</span>
            }
            <div class="left-view">
                <!-- <span>Enter OTP</span> -->
                <div class="otp-field">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input1
                           (input)="onInput($event, input2, undefined)"
                           (keydown)="onKeyDown($event, input1, undefined)"
                           (paste)="onPaste($event, [input1, input2, input3, input4, input5, input6])">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input2
                           (input)="onInput($event, input3, input1)"
                           (keydown)="onKeyDown($event, input2, input1)">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input3
                           (input)="onInput($event, input4, input2)"
                           (keydown)="onKeyDown($event, input3, input2)">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input4
                           (input)="onInput($event, input5, input3)"
                           (keydown)="onKeyDown($event, input4, input3)">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input5
                           (input)="onInput($event, input6, input4)"
                           (keydown)="onKeyDown($event, input5, input4)">
                    <input class="otp-input" type="text" appAllowNumbersOnly maxlength="1" #input6
                           (input)="onInput($event, undefined, input5)"
                           (keydown)="onKeyDown($event, input6, input5)">
                  </div>
                  
            </div>
            <button type="submit" [ngClass]="(this.verifyCounter <= 0 && this.countDownVerify) || otpCheck.length != 6 ? 'submit-button disable-button' : 'submit-button'" [disabled]="(this.verifyCounter <= 0 && this.countDownVerify)  || otpCheck.length != 6">CONTINUE</button>
            @if(enableResendOtpButton()) {
                <p class="forgot-password" (click)="resendOtp()">Didn't Receive OTP? Resend</p>
            }
            @else {
                <p class="resend-otp-text" >Resend OTP in {{ resendCountDownValueOnUI() }} </p>
            }
            
            <div class="signup">
                Don't have an account? <a href="signUp" class="login__anchor-tag">Sign up</a>
            </div>
        </div>
    </form>
