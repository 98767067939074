import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProgressService } from '../app/core/services/progress.service'
import { Observable, Subscription } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  title = 'supplier-connect-frontend';

  showLoader = false;
  progressPercent = 0;
  loader = this.loadingBar.useRef('router');
  value: any;
  constructor(private progressService: ProgressService, private loadingBar: LoadingBarService) {
    this.loader.start(0);
    this.loader.complete();

    // get the progress value
    // console.log(this.loader.value$)
    this.value = this.loader.value$;
  }
}