import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignUpComponent } from './pages/login/sign-up/sign-up.component';
import { SignInComponent } from './pages/login/sign-in/sign-in.component';
import { LoginOtpComponent } from './pages/login/login-otp/login-otp.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './pages/login/set-password/set-password.component';
import { AuthGuardService } from './core/guards/auth-guard.service';

const routes: Routes = [
  {
    path: "",
    component: SignInComponent
  },
  {
    path: "signUp",
    component: SignUpComponent
  },
  // {
  //   path: 'login-otp',
  //   component: LoginOtpComponent
  // },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'app',
    loadChildren: () => import("../app/core/modules/landing/landing.module").then(m => m.LandingModule),
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
