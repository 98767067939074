<app-landing-login>
    <div class="login__container">
        <div class="login__content">
            <div class="image">
                <img src="assets/images/login/password-icon.svg" alt="lock image">
            </div>
            <span class="login__header-text">Create a new password</span>
            <form [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()" class="login__form-class">
                <div class="login__email-group">
                    <label for="input-text-login" class="login__input-label login__required-field">Email</label>
                    <input type="text" id="input-text-login" class="login__input-field" formControlName="email" placeholder="Your Email Address" >
                </div>
                <div class="login__password-error-message">
                    @if (setPasswordForm.controls.email.invalid && setPasswordForm.controls.email.touched)  { <span>Required</span> }
                </div>
                
                <div class="login__input-group">
                    <label for="input-text-login" class="login__required-field">Password</label>
                    <div class="login__password-div">
                        <div class="login__password-eye">
                            <input [type]="password.inputType" id="input-text-login" class="login__input-field-password" formControlName="password" placeholder="Password">
                            <span type="button" (click)="toggleShowPassword(password)" class=".login__input-field-password-eye">
                                @if (!password.showPassword) { <img class="login__password-eye-image" src="assets/images/login/hide-password.svg" alt="eye"> }
                                @else { <img class="login__password-eye-image" src="assets/images/login/show-password.svg" alt="eye"> }
                            </span>
                        </div>
                        <div class="login__password-error-message">
                            @if (!setPasswordForm.controls.password.value && setPasswordForm.controls.password.touched)  { <span>This field is Required</span> }
                            @else if (setPasswordForm.controls.password.invalid && setPasswordForm.controls.password.touched)  { <span>Please Enter a Secure Password</span> }
                        </div>
                    </div>
                </div>
                <div class="login__input-group confirm-password-div">
                    <label for="input-text-login" class="login__required-field">Confirm Password</label>
                    <div class="login__password-div">
                        <div class="login__password-eye">
                            <input [type]="confirmPassword.inputType" id="input-text-login" class="login__input-field-password" formControlName="confirmPassword" placeholder="Confirm Password">
                            <span (click)="toggleShowPassword(confirmPassword)" class=".login__input-field-password-eye">
                                @if (!confirmPassword.showPassword) { <img class="login__password-eye-image" src="assets/images/login/hide-password.svg" alt="eye"> }
                                @else { <img class="login__password-eye-image" src="assets/images/login/show-password.svg" alt="eye"> }
                            </span> 
                        </div>
                        <div class="login__password-error-message">
                            @if (!setPasswordForm.controls.confirmPassword.value && setPasswordForm.controls.confirmPassword.touched)  { <span>This field is Required</span> }
                            @else if (setPasswordForm.controls.confirmPassword.invalid && setPasswordForm.controls.confirmPassword.touched)  { <span>Please Enter a Secure Password</span> }
                        </div>
                    </div>
                </div>
                <!-- Show Error message class -->
                <div class="login__password-error-message">
                    @if (setPasswordForm.controls.confirmPassword.value != setPasswordForm.controls.password.value && setPasswordForm.controls.confirmPassword.touched)  { <span>Password does not match</span> }
                </div>
                <div class="login__submit-button-class">
                    <button type="submit" [ngClass]="(setPasswordForm.controls.confirmPassword.touched && setPasswordForm.controls.confirmPassword.value && setPasswordForm.controls.password.valid)
                                                        && (setPasswordForm.controls.password.touched && setPasswordForm.controls.password.value && setPasswordForm.controls.confirmPassword.valid) 
                                                        && (setPasswordForm.controls.password.value == setPasswordForm.controls.confirmPassword.value) ? 'submit-button' : 'submit-button verify-gst__submit-button-disabled'" 
                                                        [disabled]="!((setPasswordForm.controls.confirmPassword.touched && setPasswordForm.controls.confirmPassword.value && setPasswordForm.controls.password.valid)
                                                        && (setPasswordForm.controls.password.touched && setPasswordForm.controls.password.value && setPasswordForm.controls.confirmPassword.valid) 
                                                        && (setPasswordForm.controls.password.value == setPasswordForm.controls.confirmPassword.value))">Create a Password</button>
                </div>
            </form>            
            <div class="info-text-class">
                <span class="info-text">Dont have an Account?</span>
                <a [routerLink]="['/signUp']" class="info-text-sign-up">Sign up</a>
            </div>              
        </div>
    </div>
</app-landing-login>