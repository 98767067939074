import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NoLeadingSpacesDirective } from "src/app/directives/no-leading-spaces.directive";


@NgModule({
    declarations: [ NoLeadingSpacesDirective ],
    imports: [
      CommonModule
    ],
    exports:[NoLeadingSpacesDirective]
  })
  export class NoLeadingSpacesModule { }
  