import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-landing-login',
  standalone: true,
  imports: [
    FormsModule,ReactiveFormsModule
  ],
  templateUrl: './landing-login.component.html',
  styleUrl: './landing-login.component.scss'
})
export class LandingLoginComponent implements OnInit {
  signInForm!: FormGroup;
  titleArray=["Get connected with your customers","Track your payments","Know your invoices status","Request for early payments"]
  constructor() {
    this.signInForm = new FormGroup({
      'username': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.required)
    })
  }
  ngOnInit(): void {
  }
  onSubmit() {

  }
}
