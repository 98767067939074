import { Component, Inject, inject } from '@angular/core';
import { LandingLoginComponent } from '../landing-login/landing-login.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { HttpClientModule } from '@angular/common/http';
import { NoLeadingSpacesModule } from 'src/app/core/modules/validations/numbers-only/no-leading-spaces.module';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [LandingLoginComponent, FormsModule, ReactiveFormsModule, RouterLink, HttpClientModule, NoLeadingSpacesModule],
  providers: [LoginServiceService],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  invalidForm : boolean = true;
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
 
  toastr: ToastrService = inject(ToastrService);
  loginHttpService: LoginServiceService =  inject(LoginServiceService);

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.invalidForm = true;
      return;
    }
    else {
      this.loginHttpService.forgotPassword(this.forgotPasswordForm.value.email)
      .subscribe({
        next: (data) => {
          // Handle successful response
          this.forgotPasswordForm.controls.email.setValue("");
          this.toastr.success("Password Reset Link sent to your email");
        },
        error: (error) => {
          // Handle error
          if(error.status == 400) 
            this.toastr.error("Bad Request. Make sure your email is correct");
          else 
            this.toastr.error("Something went wrong");
        },
        complete: () => {
          // Handle completion
        }
      });
    }
  }
}
