import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoLeadingSpaces]'
})
export class NoLeadingSpacesDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const input = this.el.nativeElement;
    const trimmedValue = input.value.replace(/^\s+/, '');
    if (input.value !== trimmedValue) {
      input.value = trimmedValue;
      event.preventDefault();
    }
  }
}