<app-landing-login>
    <div class="login__container">
        <div class="login__content">
            <div class="image">
                <img src="assets/images/login/password-icon.svg" alt="lock image">
            </div>
            <span class="login__header-text">Forgot Password?</span>
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" class="login__form-class">
                <div class="login__input-group">
                    <label for="input-text-login" class="login__input-label login__required-field">Email</label>
                    <input type="text" id="input-text-login" class="login__input-field" formControlName="email" appNoLeadingSpaces
                        placeholder="Your Email Address">
                    <div class="login__password-error-message">
                        @if (this.forgotPasswordForm.controls['email'].invalid && this.forgotPasswordForm.controls['email'].touched &&
                        this.forgotPasswordForm.controls['email'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.forgotPasswordForm.controls['email'].invalid && this.forgotPasswordForm.controls['email'].touched
                        )
                        {
                        <span>Please Enter Correct Email Id</span>
                        }
                    </div>
                </div>
                <div class="login__submit-button-class">
                    <button type="submit" class="submit-button">Send Password Reset Link</button>
                </div>
            </form>
            <div class="info-text-class">
                <span class="info-text">Password Reset Link Will Be Sent To Your Email</span>
            </div>
            <div class="info-text-class">
                <span class="info-text">Dont Have An Account?</span>
                <a [routerLink]="['/signUp']" class="login__anchor-tag">Sign Up</a>
            </div>
        </div>
    </div>
</app-landing-login>