<app-landing-login>
    <div class="login__container">
        <form class="login-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
            <div>
                <h2 class="login-heading">SIGN UP</h2>
            </div>
            <div class="scrollable-div">
                <div class="login__input-group">
                    <label for="firstName" class="login__input-label login__required-field">First Name</label>
                    <input type="text" placeholder="First Name" formControlName="firstName" appNoLeadingSpaces appAlphanumeric
                        class="login__input-field"  maxlength="30" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['firstName'].invalid &&
                        this.signUpForm.controls['firstName'].touched )
                        {
                        <span>This Field Is Required.</span>
                        }
                    </div>
                </div>
                <div class="login__input-group">
                    <label for="lastName" class="login__input-label login__required-field">Last Name</label>
                    <input type="text" placeholder="Last Name" formControlName="lastName" class="login__input-field" appNoLeadingSpaces appAlphanumeric maxlength="30" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['lastName'].invalid &&
                        this.signUpForm.controls['lastName'].touched )
                        {
                        <span>This Field Is Required.</span>
                        }
                    </div>
                </div>
                <div class="login__input-group">
                    <label for="text" class="login__input-label login__required-field">Email</label>
                    <input type="text" placeholder="Email Address" formControlName="email" appNoLeadingSpaces
                        class="login__input-field"  maxlength="50"/>
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['email'].invalid && this.signUpForm.controls['email'].touched &&
                        this.signUpForm.controls['email'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['email'].invalid && this.signUpForm.controls['email'].touched
                        )
                        {
                        <span>Please Enter Correct Email Id</span>
                        }
                    </div>
                </div>
                <div class="login__input-group">
                    <label for="input-text-login" class="login__required-field">Password</label>
                    <div class="login__password-div">
                        <div class="login__password-eye">
                            <input [type]="password.inputType" id="input-text-login" class="login__input-field-password"
                                formControlName="password" placeholder="Password">
                            <span type="button" (click)="toggleShowPassword(password)"
                                class=".login__input-field-password-eye">
                                @if (!password.showPassword) { <img class="login__password-eye-image"
                                    src="assets/images/login/hide-password.svg" alt="eye"> }
                                @else { <img class="login__password-eye-image"
                                    src="assets/images/login/show-password.svg" alt="eye"> }
                            </span>
                        </div>
                        <div class="login__password-error-message">
                            @if (this.signUpForm.controls['password'].invalid &&
                            this.signUpForm.controls['password'].touched)
                            { <span>Please Enter Complex Password</span> }
                        </div>
                    </div>
                </div>
                <div class="login__input-group">
                    <label for="input-text-login" class="login__required-field">Confirm Password</label>
                    <div class="login__password-div">
                        <div class="login__password-eye">
                            <input [type]="confirmPassword.inputType" id="input-text-login"
                                class="login__input-field-password" formControlName="confirmPassword"
                                placeholder="Confirm Password">
                            <span type="button" (click)="toggleShowPassword(confirmPassword)"
                                class=".login__input-field-password-eye">
                                @if (!confirmPassword.showPassword) { <img class="login__password-eye-image"
                                    src="assets/images/login/hide-password.svg" alt="eye"> }
                                @else { <img class="login__password-eye-image"
                                    src="assets/images/login/show-password.svg" alt="eye"> }
                            </span>
                        </div>
                        <div class="login__password-error-message">
                            @if (
                            this.signUpForm.controls['confirmPassword'].touched &&
                            this.signUpForm.controls['confirmPassword'].value!=this.signUpForm.controls['password'].value
                            )
                            {
                            <span>Password Didn't Matched</span>
                            }

                            @if (this.invalidForm &&
                            this.signUpForm.controls['confirmPassword'].touched)
                            {
                            <span>Password Didn't Matched</span>
                            }
                        </div>
                    </div>
                </div>
                <!-- <div class="login__input-group">
                    <label for="organization" class="login__input-label login__required-field">Organization</label>
                    <input type="text" placeholder="Organization/Shop name" formControlName="organization"
                        class="login__input-field" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['organization'].invalid &&
                        this.signUpForm.controls['organization'].touched )
                        {
                        <span>This field is required.</span>
                        }
                    </div>
                </div> -->
                <!-- <div class="login__input-group">
                    <label for="gstIn" class="login__input-label login__required-field">GSTIN No</label>
                    <input type="text" placeholder="GSTIN No" formControlName="gstIn" class="login__input-field" (blur)="autoFillPanFromGstin()" appNoLeadingSpaces />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['gstIn'].invalid && this.signUpForm.controls['gstIn'].touched &&
                        this.signUpForm.controls['gstIn'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['gstIn'].invalid && this.signUpForm.controls['gstIn'].touched
                        )
                        {
                        <span>Please Enter Correct GSTIN Number</span>
                        }
                    </div>
                </div> -->
                <div class="login__input-group">
                    <label for="panNo" class="login__input-label login__required-field">PAN No</label>
                    <input type="text" placeholder="PAN No" formControlName="panNo" class="login__input-field" (blur)="autoFillCompanyNameFromPan()" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['panNo'].invalid && this.signUpForm.controls['panNo'].touched &&
                        this.signUpForm.controls['panNo'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['panNo'].invalid && this.signUpForm.controls['panNo'].touched
                        )
                        {
                        <span>Please Enter Correct PAN Number</span>
                        }
                        <!-- @else if(this.signUpForm.controls['gstIn'].valid && this.signUpForm.controls['gstIn'].touched && this.signUpForm.controls["gstin"].) {
                        {{autoFillPanFromGstin()}}
                        } -->
                    </div>
                </div>
                <!-- <div class="login__input-group">
                    <label for="gstIn" class="login__input-label">GSTIN No</label>
                    <input type="text" placeholder="GSTIN No" formControlName="gstIn" class="login__input-field" (blur)="autoFillPanFromGstin()" appNoLeadingSpaces />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['gstIn'].invalid && this.signUpForm.controls['gstIn'].touched &&
                        this.signUpForm.controls['gstIn'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['gstIn'].invalid && this.signUpForm.controls['gstIn'].touched
                        )
                        {
                        <span>Please Enter Correct GSTIN Number</span>
                        }
                    </div>
                </div> -->
                <div class="login__input-group">
                    <label for="companyName" class="login__input-label login__required-field">Company Name</label>
                    <input type="text" placeholder="Company Name" formControlName="companyName" appNoLeadingSpaces
                        class="login__input-field"  maxlength="30"/>
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['companyName'].invalid &&
                        this.signUpForm.controls['companyName'].touched )
                        {
                        <span>This Field Is Required.</span>
                        }
                    </div>
                </div>

                <!-- <div class="login__input-group">
                    <label for="govUsername" class="login__input-label">Gov Registered Username</label>
                    <input type="text" placeholder="Username" formControlName="govRegUsername" 
                        class="login__input-field" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['govRegUsername'].invalid &&
                        this.signUpForm.controls['govRegUsername'].touched &&
                        this.signUpForm.controls['govRegUsername'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['govRegUsername'].invalid &&
                        this.signUpForm.controls['govRegUsername'].touched )
                        {
                        <span>Please Enter Correct Gov Registered Username</span>
                        }
                    </div>
                </div> -->
                <div class="login__input-group">
                    <label for="mobileNo" class="login__input-label login__required-field">Mobile No</label>
                    <input type="text" appAllowNumbersOnly placeholder="Mobile No" formControlName="mobileNo" appNoLeadingSpaces
                        class="login__input-field" />
                    <div class="login__password-error-message">
                        @if (this.signUpForm.controls['mobileNo'].invalid &&
                        this.signUpForm.controls['mobileNo'].touched && this.signUpForm.controls['mobileNo'].value=="")
                        {
                        <span>This Field Is Required.</span>
                        }
                        @else if(this.signUpForm.controls['mobileNo'].invalid &&
                        this.signUpForm.controls['mobileNo'].touched )
                        {
                        <span>Please Enter Correct Mobile Number</span>
                        }
                    </div>
                </div>
            </div>
            <div class="sign-in">
                <button type="submit" [disabled]="!this.signUpForm.valid" class="submit-button">SIGN UP</button>
            </div>
            <div class="login__text-center sign-in-div">
                <span>Already Have An Account?</span><a routerLink="" class="login__anchor-tag"> Sign In</a>
            </div>
        </form>
    </div>
</app-landing-login>